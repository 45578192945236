import React from 'react';
import '../Poems1.css'; // Импортируем стили

const Poems3 = () => {
    return (
        <div className="poems__container">
            <div className="poems__block__poem" style={{fontSize: '23px'}}>
                <p>
                    Солнце. Яркое, так что жмурит глаза.<br/>
                    Всюду листья, цветы, зеленеет трава,<br/>
                    Но от этого не теплее мы.<br/>
                    Между нами сейчас зима.<br/>
                    Солнце. Яркое, так что жмурит глаза<br/>
                    Отражается от наших душ<br/>
                    Мы одеты в хитин. И из бронзы сердца.<br/>
                    Не отыщешь от них ключ.<br/>
                    Солнце. Яркое, так что жмурит глаза.<br/>
                    Чтоб друг другу в лицо не смотреть.<br/>
                    Потому что теперь<br/>
                    Не отступим назад,<br/>
                    Потому что сейчас слово - плеть.<br/>
                    Солнце. Яркое, так что жмурит глаза.<br/>
                    Но мне хочется их открыть.<br/>
                    Потому что я знаю, что я не прав<br/>
                    Что вот-вот перережу нить.<br/>
                    Потому что сейчас<br/>
                    Солнце светит на нас<br/>
                    И сквозь бронзу греют лучи<br/>
                    Маленький уголок<br/>
                    Где таится цветок<br/>
                    Нашей трепетной детской души.<br/>
                    Потому что в конце панцирь спадёт,<br/>
                    И тогда расцветем вновь.<br/>
                    Чтоб ни было сейчас,<br/>
                    Это точно пройдёт,<br/>
                    Верь, всегда побеждает любовь.
                </p>
            </div>
        </div>
    );
};

export default Poems3;

import React from 'react';
import '../Poems1.css'; // Импортируем стили
const flowersImage = require('../assets/flowers3.jpg'); // Импортируем изображение

const Poems1 = () => {
    return (
        <div className="poems__container">
            <div style={{display: 'flex', marginLeft: '200px'}}>
            <div className="poems__left">
                <div className="poems__block__text">
                    <p>
                        Вот стихи, написанные когда-то для тебя. Да, я менялся, мы с тобой с некоторыми вещами там не согласны. Но мои чувства к тебе там искренние, поэтому хотя бы они заслуживают быть здесь.
                    </p>
                </div>
                <div className="poems__image__container">
                    <img src={flowersImage} alt="Flowers" className="poems__image"/>
                    <p className="poems__image__caption">
                        А это единственная фотография твоих цветов, которая у меня есть. Поэтому, раз по-другому
                        подарить не получится, подарю тебе их здесь ещё раз. Нельзя же тебя без цветов в день рождения
                        оставить.
                    </p>
                </div>
            </div>
            <div className="poems__right">
                <div className="poems__block__poem">
                    <p>
                        Мне было темно и пусто <br/>
                        Я жёг свою жизнь назло <br/>
                        Костер горел быстро и тускло <br />
                        И не давал тепло<br />
                        Мне было темно и пусто <br />
                        Я шёл и не видел свет <br />
                        Существование глупость <br />
                        Без смысла я был нелеп<br />
                        И новым всполохом пламя <br />
                        В сиянье явилась ты <br />
                        Так недоступно играясь <br />
                        И навивая мечты <br />
                        Мне стало так ярко, что жмурюсь <br />
                        Что кружится голова <br />
                        И ты так манишь, волнуешь<br />
                        И, кажется, жизнь - игра <br />
                        Но только одно тревожит <br />
                        И не позволяет уснуть <br />
                        Когда-нибудь мы не сможем<br />
                        Гореть. И закончится путь <br />
                        Я так боюсь, что растает <br />
                        И разобьётся мечта <br />
                        Я плачу, осознавая, <br />
                        Что это не навсегда <br />
                        И если мы знаем, что вместе <br />
                        Не суждено нам дожить <br />
                        Давай разгоримся вместе <br />
                        Давай сейчас ярче светить..
                    </p>
                </div>
            </div>
            </div>
        </div>
    );
};

export default Poems1;
